export const alphaNumericRegex = /[a-zA-Z0-9_\s]/;
export const alphaNumericGrammarRegex = /[a-zA-Z0-9À-ÖØ-öø-ÿ_\s|¿¡]/;
export const phraseHintsRegex = /[a-zA-Z0-9À-ÖØ-öø-ÿ_\s$,{}¿¡.]/;
export const numberOrVariable = /[a-zA-Z0-9\-{}+]/;
export const numbersOnly = /[0-9]/;
export const specialCharOnly = /[^A-Za-z0-9]/;
export const lowercaseOnly = /[a-z]/;
export const uppercaseOnly = /[A-Z]/;
export const repeatChar = /(\w)(\1+\1+\1+\1+)/;
export const numbersOrStar = /[0-9*]/;
export const alphaNumExclSpaceRegex = /[a-zA-Z0-9_]/;
export const alphaNumericDashesOnly = /^[A-Za-z0-9-]*$/;
export const alphaNumericLatinRegex = /[a-zA-Z0-9_\p{L}]/;
