<template>
  <div v-if="formAction" style="padding: 20px">
    <div>
      <div class="titles">{{ __("ID") }}</div>
      <div class="details">{{ contentFormInfo.wsl_profile_id }}</div>
      <div class="titles">{{ __("Name") }}</div>
      <div class="details">{{ contentFormInfo.profile_name }}</div>
      <div class="titles">{{ __("API key") }}</div>
      <div class="details">{{ contentFormInfo.api_key }}</div>
      <div class="titles">{{ __("API URL") }}</div>
      <div class="details">{{ contentFormInfo.base_api_url }}</div>
      <div class="titles">{{ __("Speakers Overview") }}</div>
      <div class="box">
        <el-scrollbar :native="true">
          <div class="contentListContainer">
            <el-table
              ref="speakersTable"
              v-loading="isSubmitting"
              :data="this.contentFormInfo.well_said_labs_speakers.data"
              class="list-table"
            >
              <el-table-column :label="__('ID')">
                <template slot-scope="scope">
                  <span>{{ scope.row.speaker_id }}</span>
                </template>
              </el-table-column>
              <el-table-column :label="__('Name')">
                <template slot-scope="scope">
                  <span>{{ scope.row.speaker_name }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-scrollbar>
      </div>
    </div>

    <div style="margin-top: 20px;">
      <el-dropdown
        @command="command => handleAction(command, contentFormInfo)"
        placement="bottom-start"
      >
        <div>
          <span class="wrapper">
            <el-button type="primary" class="actionBtn">
              {{ __("Actions")
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
          </span>
        </div>
        <el-dropdown-menu slot="dropdown" style="width: 200px;">
          <el-dropdown-item command="edit" :disabled="!this.canWrite()">
            <img
              class="action-icon"
              :src="require('@/assets/icons/campaign_actions/icon_pencil.svg')"
            />
            <span>{{ __("Edit") }}</span>
          </el-dropdown-item>
          <!--          <el-dropdown-item command="checkInUse">-->
          <!--            <img-->
          <!--              class="action-icon"-->
          <!--              :src="require('@/assets/icons/campaign_actions/icon_play.svg')"-->
          <!--            />-->
          <!--            <span>Check in use</span>-->
          <!--          </el-dropdown-item>-->
          <el-dropdown-item command="delete" :disabled="!this.canWrite()">
            <img
              class="action-icon"
              :src="
                require('@/assets/icons/campaign_actions/icon_trash_new.svg')
              "
            />
            <span>{{ __("Delete") }}</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <el-dialog
      :visible.sync="openModal"
      fullscreen
      :show-close="false"
      custom-class="editContentItemModal"
      destroy-on-close
      v-loading="isSubmitting"
    >
      <div class="auth-profile-form-dialog">
        <el-row type="flex">
          <el-col :span="12" :offset="6">
            <page-header
              :title="__('Well Said Labs Profile')"
              :contentId="id"
            />
          </el-col>
        </el-row>

        <el-scrollbar :native="false">
          <el-form
            ref="contentForm"
            :rules="rules"
            :model="contentForm"
            label-position="top"
            size="large"
          >
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item prop="profile_name" :label="__('Name')">
                  <el-input v-model="contentForm.profile_name"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item prop="api_key" :label="__('API key')">
                  <el-input v-model="contentForm.api_key"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item prop="base_api_url" :label="__('API URL')">
                  <el-input v-model="contentForm.base_api_url"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-scrollbar :native="false">
                  <div style="max-height: 40vh">
                    <el-form-item
                      prop="well_said_labs_speakers.data"
                      :label="__('Speakers')"
                    >
                      <el-table
                        class="variable-rules"
                        fit
                        :data="speakerParams"
                        style="width: 100%;"
                      >
                        <el-table-column
                          prop="speaker_id"
                          :label="__('Speaker Id')"
                        >
                          <template slot-scope="scope">
                            <el-row type="flex">
                              <el-col>
                                <el-form-item
                                  :error="scope.row.speaker_id_conflict"
                                >
                                  <el-input
                                    v-model="scope.row.speaker_id"
                                    v-permit="numbersOnly"
                                  ></el-input>
                                </el-form-item>
                              </el-col>
                            </el-row>
                          </template>
                        </el-table-column>
                        <el-table-column
                          prop="speaker_name"
                          :label="__('Name')"
                        >
                          <template slot-scope="scope">
                            <el-row type="flex">
                              <el-col>
                                <el-form-item>
                                  <el-input
                                    v-model="scope.row.speaker_name"
                                  ></el-input>
                                </el-form-item>
                              </el-col>
                            </el-row>
                          </template>
                        </el-table-column>
                        <el-table-column prop="gender" :label="__('Gender')">
                          <template slot-scope="scope">
                            <el-col>
                              <el-form-item>
                                <el-select
                                  v-model="scope.row.gender"
                                  :placeholder="__('Select Gender')"
                                  default-first-option
                                  filterable
                                  collapse-tags
                                  type="flex"
                                  style="width: 100%"
                                >
                                  <el-option
                                    :label="__('Male')"
                                    value="M"
                                  ></el-option>
                                  <el-option
                                    :label="__('Female')"
                                    value="F"
                                  ></el-option>
                                </el-select>
                              </el-form-item>
                            </el-col>
                          </template>
                        </el-table-column>
                        <el-table-column
                          class-name="cell-item-pointer"
                          width="40px"
                        >
                          <template slot-scope="scope">
                            <span @click="removeSpeaker(scope.$index)">
                              <i class="el-icon-circle-close"></i>
                            </span>
                          </template>
                        </el-table-column>
                      </el-table>
                      <!--                      <query-params-->
                      <!--                        v-model="speakerParams"-->
                      <!--                        :use-variables-in-key="false"-->
                      <!--                        :use-variables-in-value="false"-->
                      <!--                        :use-default-field="false"-->
                      <!--                        :use-default-key-field="false"-->
                      <!--                        keyLabel="speaker ID"-->
                      <!--                        valueLabel="speaker name"-->
                      <!--                      ></query-params>-->
                    </el-form-item>
                  </div>
                </el-scrollbar>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item>
                  <save-button
                    type="primary"
                    @click="submitForm"
                    class="submitBtn"
                    :disabled="!this.canWrite()"
                    :primaryKey="id"
                    variant="CreateUpdate"
                  />
                  <el-button @click="handleCancel" class="cancelBtn"
                    >{{ __("Cancel") }}
                  </el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-scrollbar>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { EventBus } from "@/EventBus";
import PageHeader from "@/components/PageHeader";
import { mapActions, mapState } from "vuex";
import BaseContentInfoPanel from "@/views/build/content/mixins/BaseContentInfoPanel";
import BaseContent from "@/views/build/content/mixins/BaseContent";
// import QueryParams from "@/views/build/callflow/components/node-type-forms/components/QueryParams";
import _ from "lodash";
import {
  filterRowsIfSomeKeyValueIsAbsent,
  getSubKeyObject
} from "@/utils/collection";
import { numbersOnly } from "@/utils/regex";
import { validateWslProfile } from "@/api/wslprofiles";
import SaveButton from "@/components/SaveButton";

export default {
  mixins: [BaseContent, BaseContentInfoPanel],
  components: {
    PageHeader,
    SaveButton
    // QueryParams
  },

  computed: {
    ...mapState("app", {
      selectedAccountId: state => state.selectedAccountId
    }),
    ...mapState("wslprofiles", {
      isLoading: state => state.loading
    }),
    speakerParams: {
      get() {
        const { data: speakersData } = this.allSpeakersData;
        return this.isSubmitting ? this.speakersBackup : speakersData;
      },
      set(val) {
        this.contentForm.well_said_labs_speakers.data = val;
        this.speakersBackup = _.cloneDeep(val);
      }
    },

    allSpeakersData() {
      return _.isEmpty(this.contentForm.well_said_labs_speakers.data)
        ? { data: [] }
        : {
            data: _.map(
              this.contentForm.well_said_labs_speakers.data,

              (speakerCondition, index) => {
                speakerCondition["speaker_id_conflict"] = "";

                const subKeys = ["speaker_id", "speaker_name", "gender"];

                let speakerRemoveLastEmptyRow = _.cloneDeep(
                  this.contentForm.well_said_labs_speakers.data
                );
                let speakerCollectionSize = _.size(speakerRemoveLastEmptyRow);
                if (speakerCollectionSize > 1) {
                  speakerRemoveLastEmptyRow.splice(
                    speakerCollectionSize - 1,
                    1
                  );
                }

                if (speakerCollectionSize - 1 !== index) {
                  speakerCondition["speaker_id_conflict"] =
                    _.some(
                      speakerRemoveLastEmptyRow,
                      condition =>
                        !_.isEqual(
                          getSubKeyObject(speakerCondition, subKeys),
                          getSubKeyObject(condition, subKeys)
                        ) &&
                        speakerCondition.speaker_id.toString().trim() ===
                          condition.speaker_id.toString().trim()
                    ) &&
                    !_.isEmpty(speakerCondition.speaker_id.toString().trim())
                      ? "Speaker Id conflict"
                      : "";
                }

                return speakerCondition;
              }
            )
          };
    }
  },

  data() {
    let validateWslProfileName = async (rule, value, callback) => {
      let hasError = false;
      await validateWslProfile(value)
        .then(res => {
          if (res.data.found && value !== this.contentFormInfo.profile_name) {
            hasError = true;
          }
        })
        .catch(() => {
          hasError = false;
        });

      if (hasError) {
        callback(__("Well Said Labs profile already exists"));
      } else {
        callback();
      }
    };

    return {
      speaker_id_conflict: "",
      numbersOnly: numbersOnly,
      isSubmitting: false,
      speakersBackup: [],
      rules: {
        profile_name: [
          {
            required: true,
            message: __("Well Said Labs Profile name is required"),
            trigger: "blur"
          },
          {
            validator: validateWslProfileName,
            trigger: "blur"
          }
        ],
        api_key: [
          {
            required: true,
            message: __("API key is required"),
            trigger: "blur"
          }
        ],
        base_api_url: [
          {
            required: true,
            message: __("API URL is required"),
            trigger: "blur"
          }
        ]
      }
    };
  },

  methods: {
    ...mapActions("wslprofiles", {
      createWslProfile: "createWslProfile",
      updateWslProfile: "updateWslProfile",
      deleteContentMethod: "deleteWslProfile",
      undoDeleteContent: "undoDeleteWslProfile"
    }),

    handleAction(command, contentFormInfo) {
      if (command === "edit") {
        this.handleEdit();
      } else if (command === "delete") {
        this.handleDelete(contentFormInfo);
      }
    },

    removeSpeaker(index) {
      this.contentForm.well_said_labs_speakers.data.splice(index, 1);
    },

    formHasErrors() {
      const { data: speakers_data } = this.allSpeakersData;
      return _.some(speakers_data, condition => {
        return (
          condition.speaker_id_conflict && condition.speaker_id_conflict.length
        );
      });
    },

    submitForm() {
      this.$refs.contentForm.validate(valid => {
        if (valid && !this.formHasErrors()) {
          this.isSubmitting = true;
          const process =
            this.id === -1 ? this.createWslProfile : this.updateWslProfile;

          this.contentForm.is_enabled = 1;
          this.contentForm.ac_id = this.selectedAccountId;

          let filteredData = filterRowsIfSomeKeyValueIsAbsent(
            this.contentForm.well_said_labs_speakers.data,
            "speaker_id, speaker_name, gender"
          );
          this.$set(
            this.contentForm.well_said_labs_speakers,
            "data",
            filteredData
          );
          process(this.contentForm)
            .then(() => {
              this.isSubmitting = false;

              this.id === -1
                ? this.$message({
                    name: "success",
                    message: __("Well Said Labs Profile added successfully")
                  })
                : this.$message({
                    type: "success",
                    message: __("Well Said Labs Profile updated successfully")
                  });
              EventBus.$emit("list-changed");
              this.handleCancel();
            })
            .catch(err => {
              this.isSubmitting = false;
              this.$message({
                type: "error",
                message: err.message
              });
            });
        }
      });
    },

    addNewSpeakerParam() {
      const data = [{ speaker_id: "", speaker_name: "", gender: "M" }];
      this.contentForm.well_said_labs_speakers.data.push(...data);
    }
  },

  watch: {
    speakerParams: {
      handler() {
        let speakerData = _.cloneDeep(
          this.contentForm.well_said_labs_speakers.data
        );
        let speakerCollectionSize = _.size(speakerData);
        let validSize = _.size(
          filterRowsIfSomeKeyValueIsAbsent(
            speakerData,
            "speaker_id, speaker_name, gender"
          )
        );
        if (validSize === speakerCollectionSize && !this.isSubmitting) {
          this.addNewSpeakerParam();
        }
      }
    },
    openModal(val) {
      if (val) {
        if (this.id !== -1) {
          // convert the type of speaker id from int to string
          // so that filterRowsIfSomeKeyValueIsAbsent can be reused
          let speakerData = _.cloneDeep(
            this.contentForm.well_said_labs_speakers.data
          );
          if (speakerData) {
            _.forEach(speakerData, function(column) {
              column.speaker_id = column.speaker_id.toString();
            });
          }
          this.$set(
            this.contentForm.well_said_labs_speakers,
            "data",
            speakerData
          );
          this.addNewSpeakerParam();
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/node_common.scss";

.titles {
  color: #6e7681;
  font-weight: bold;
  margin-top: 10px;
}

.details {
  word-wrap: break-word;
  color: #a0a8b5;
  font-size: 0.85em;
  margin-top: 5px;
}

.submitBtn {
  height: 44px;
  background: $content-theme-color;
  border: none;

  &:hover {
    background: $content-theme-hover-color;
  }
}

.cancelBtn {
  border: none;
  height: 44px;

  &:hover {
    background: lightgrey;
    color: white;
  }
}

.editBtn {
  margin-top: 10px;
  height: 44px;
  background: $content-theme-color;
  border: none;
  color: white;
  font-size: 0.8em;
  font-weight: bold;

  &:hover {
    color: white;
    background: $content-theme-hover-color;
  }
}

::v-deep .el-select {
  .el-input.is-focus .el-input__inner {
    border-color: $content-theme-color;
  }

  .el-input__inner:hover {
    border-color: $content-theme-color;
  }

  .el-select-dropdown__item.selected {
    color: $content-theme-color;
  }

  .el-input__inner:focus {
    border-color: $content-theme-color;
  }
}
</style>
