<script>
import List from "./pages/list";
import CreateOrEdit from "./pages/createOrEdit";
import BaseIndex from "@/views/build/content/mixins/BaseIndex";
import { mapState } from "vuex";

export default {
  name: "WSLProfilesManageIndex",
  mixins: [BaseIndex],
  components: {
    List,
    CreateOrEdit
  },

  data() {
    return {
      selectedContent: this.initContent()
    };
  },

  computed: {
    ...mapState("wslprofiles", {
      contents: state => state.wslProfiles
    })
  },

  methods: {
    initContent() {
      return {
        profile_name: "",
        api_key: "",
        base_api_url: "https://api.wellsaidlabs.com/v1/tts/stream",
        well_said_labs_speakers: {
          data: []
        }
      };
    }
  },

  mounted() {
    this.contentType = "WellSaidLabsProfile";
    this.primaryKey = "wsl_profile_id";
    this.scope = "ac";
  }
};
</script>

<style lang="scss" scoped></style>
